import React, { Component } from 'react';
import FilterItem from './FilterItem';
import { withStyles } from '@material-ui/core/styles';
import { DrcButton, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { connect } from 'react-redux';
import CreateFilter from '../../utils/filter';
import { setDataSetupFilters } from '../../actions/FilterActions';
import { LOOKUP_FIELDS } from '../../data/constants';
import FilterSelect from './FilterSelect';
import { filterFields } from './fields';
import { makeOptions } from '../../utils/helper';
const styles = (_theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flex: 1,
        border: `0.2px solid #b9b9b9`,
        padding: '8px',
        borderRadius: '4px'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
    },
    filterOptions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    filterButtonContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    selectedFilter: {
        height: '2rem'
    },
    selectedContainer: {
        flex: 5,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start'
    }
});

class Filter extends Component {
    componentDidMount() {
        let filters = this.makeFilter(filterFields);
        this.props.setDataSetupFilters(filters);
    }

    filterOptions = {
        EstimateGroup: this.props.estimateGroupLookUp,
        Commodity: this.props.commodityOffsetWeekLookUp,
        // ReportType:this.props.,
        CustomerCodeCategory: this.props.customerCodeCategoryLookUp,
        GrowerCategory: this.props.growerCategoryLookUp,
        // Geography:this.props.geo,
        FamilyEntity: this.props.familyEntityLookUp,
        LocationCode: this.props.locationLookUp,
        CoolerName: this.props.coolerNameLookUp,
        Application: this.props.applicationLookUp,
        PackageUOM: this.props.uomLookUp,
        FreightUOM: this.props.uomLookUp,
        GrowerModule: this.props.growerModule,
        PoolWeek: makeOptions(this.props.yearPoolWeeks, 'PoolWeek', 'PoolWeek'),
        LookupType: makeOptions(this.props.lookupTypeOptions, 'LookupType', 'LookupType'),
        VendorCode: this.props.growerType
            ? this.props.vendorCodeOptions.filter((grower) => grower.Geography === this.props.growerType)
            : this.props.vendorCodeOptions,
        GrowerID: this.props.growerType
            ? this.props.vendorCodeOptions.filter((grower) => grower.Geography === this.props.growerType)
            : this.props.vendorCodeOptions
    };

    makeFilter = (filters) =>
        this.props.filterNames
            .reduce((acc, name) => {
                let foundFilter = filters.find((filter) => filter.FieldName === name);
                if (foundFilter && foundFilter.IsSearchable && this.props.filterNames.includes(foundFilter.FieldName)) {
                    let optionsAvailable = this.filterOptions[foundFilter.FieldName];
                    if (optionsAvailable) {
                        foundFilter.Options = optionsAvailable.map((option) => ({
                            label: option.LookupCode,
                            value: option.LookupCode,
                            ...option
                        }));
                    }
                    /**
                     * BUG ID : PE-3095
                     */
                    if (foundFilter.lookupFieldMapping) {
                        Object.keys(foundFilter.lookupFieldMapping).map((key) => {
                            const lookupObj = this.props.masterReducerLookUp[foundFilter.lookupFieldMapping[key]];
                            foundFilter.customProps[key] = lookupObj?.length > 0 ? parseInt(lookupObj[0].Meaning) : foundFilter.customProps[key];
                        });
                    }
                    acc.push(foundFilter);
                }
                return acc;
            }, [])
            .map(CreateFilter);

    handleValidationDetected = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        let updatedFilters = this.props.filters.map((filter) => {
            if (filter.fieldName === fieldName) {
                filter.hasError = validate;
            }
            return { ...filter };
        });

        this.props.setDataSetupFilters(updatedFilters);
    };

    filterSelect = (fieldName, value, bool) => {
        let updatedFilters = this.props.filters.map((filter) => {
            if (filter.fieldName === fieldName) {
                filter.isSelected = true;

                if ((filter.hasOptions || filter.isAsync) && !filter.isCheckbox) {
                    if (value) {
                        filter.selected = Array.isArray(value) ? value : value ? [value] : [];
                    } else {
                        filter.selected = filter.isBerry ? [value] : [...filter.selected, value];
                    }
                } else if (filter.hasOptions && filter.isCheckbox) {
                    if (bool) {
                        filter.selected = [...filter.selected, value];
                    } else {
                        filter.selected = filter.selected.filter((val) => val !== value);
                    }
                } else {
                    if (filter.isBool) {
                        filter.isSelected = value;
                    } else {
                        filter.value = value;
                    }
                }
                if (filter.hasOptions && !filter.selected.length) {
                    filter.isSelected = false;
                }
            }
            return { ...filter };
        });
        let hasFilters = updatedFilters.filter((e) => e.isSelected).length;
        if (!hasFilters) {
            this.resetFilter();
        } else {
            this.props.setDataSetupFilters(updatedFilters);
        }
    };

    resetFilter = () => {
        let clearedFilters = this.props.filters.map((filter) => {
            if (filter.isSelected) {
                if (filter.hasOptions || filter.isAsync) {
                    filter.isSelected = false;
                    filter.value = '';
                    filter.selected = [];
                } else {
                    filter.isSelected = false;
                    filter.value = '';
                }
            }
            return filter;
        });

        this.props.setDataSetupFilters(clearedFilters);
        this.props.resetFilter();
    };

    filterDeselect = (filterToRemove) => {
        let atIndex = null;
        let filterToUpdate = this.props.filters.find((e, indx) => {
            if (e.fieldName === filterToRemove.fieldName) {
                atIndex = indx;
                return true;
            }
            return false;
        });
        let updatedFilters = [...this.props.filters];

        if (updatedFilters[atIndex].hasOptions) {
            if (updatedFilters[atIndex].isMulti && !updatedFilters[atIndex].isCheckbox) {
                filterToUpdate.selected = filterToUpdate.selected.filter((itm) => !(itm.value === filterToRemove.value));
            } else if (updatedFilters[atIndex].isMulti && updatedFilters[atIndex].isCheckbox) {
                filterToUpdate.selected = filterToUpdate.selected.filter((itm) => !(itm === filterToRemove.value));
            } else filterToUpdate.selected = [];
        }
        if (updatedFilters[atIndex].isAsync) {
            filterToUpdate.selected = null;
        }

        if (!filterToUpdate.selected.length || (!updatedFilters[atIndex].hasOptions && !updatedFilters[atIndex].isAsync)) {
            filterToUpdate.isSelected = false;
            filterToUpdate.value = '';
        }

        updatedFilters[atIndex] = { ...filterToUpdate };
        this.props.setDataSetupFilters(updatedFilters);
        let hasFilters = updatedFilters.filter((e) => e.isSelected).length;
        if (!hasFilters) {
            this.resetFilter();
        }
    };

    isSelected = () => this.props.filters.filter((e) => e.isSelected).length;

    applyFilter = () => {
        //clearDAta
        this.props.applyFilter();
    };
    render() {
        const { classes, filters, showFilter } = this.props;
        return (
            <>
                {showFilter ? (
                    <div className={classes.container}>
                        <div className={classes.filterContainer}>
                            {filters
                                .map((filter, indx) =>
                                    filter.fieldName !== 'BerryType' ? (
                                        <FilterItem
                                            key={indx}
                                            filter={filter}
                                            filterSelect={this.filterSelect}
                                            filterDeselect={this.filterDeselect}
                                            handleValidationDetected={this.handleValidationDetected}
                                        />
                                    ) : null
                                )
                                .filter((e) => e)}
                        </div>
                        <div className={classes.filterOptions}>
                            <div className={classes.selectedContainer}>
                                <FilterSelect selectedFilters={filters.filter((e) => e.isSelected)} filterDeselect={this.filterDeselect} />
                            </div>
                            <div className={classes.filterButtonContainer}>
                                {this.isSelected() ? (
                                    <DrcButton
                                        isPrimary
                                        className={classes.selectedFilter}
                                        onClick={this.applyFilter}
                                        disabled={!!this.props.filters.find((itm) => itm.hasError)}
                                    >
                                        <DrcTranslate>{'Apply'}</DrcTranslate>
                                    </DrcButton>
                                ) : (
                                    ''
                                )}
                                {this.isSelected() ? (
                                    <DrcButton isSecondary className={classes.selectedFilter} onClick={this.resetFilter}>
                                        <DrcTranslate>{'Reset'}</DrcTranslate>
                                    </DrcButton>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setDataSetupFilters: (filters) => dispatch(setDataSetupFilters(filters))
});

function mapStateToProps({ filterReducer, masterReducer, lookupReducer, vendorCodeReducer }) {
    return {
        filters: filterReducer.filters,
        masterReducerLookUp: masterReducer,
        locationLookUp: masterReducer[LOOKUP_FIELDS.LocationLookUp],
        commodityLookup: masterReducer[LOOKUP_FIELDS.CommodityLookup],
        customerCodeCategoryLookUp: masterReducer[LOOKUP_FIELDS.CustomerCodeCategoryLookUp],
        uomLookUp: masterReducer[LOOKUP_FIELDS.UOMLookUp],
        growerModule: masterReducer[LOOKUP_FIELDS.GrowerModule],
        growerCategoryLookUp: masterReducer[LOOKUP_FIELDS.GrowerCategoryLookUp],
        applicationLookUp: masterReducer[LOOKUP_FIELDS.ApplicationLookUp],
        estimateGroupLookUp: masterReducer[LOOKUP_FIELDS.EstimateGroupLookUp],
        coolerNameLookUp: masterReducer[LOOKUP_FIELDS.CoolerNameLookUp],
        familyEntityLookUp: masterReducer[LOOKUP_FIELDS.FamilyEntityLookUp],
        commodityOffsetWeekLookUp: masterReducer[LOOKUP_FIELDS.CommodityOffsetWeekLookUp],
        lookupTypeOptions: lookupReducer.records,
        vendorCodeOptions: vendorCodeReducer.vendorCodeOptions,
        yearPoolWeeks: masterReducer.yearPoolWeeks
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Filter));
